import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import title_image from '../../images/titles/business_process_improvement.jpg'
import lean_process from '../../images/pages/lean_process.png'

const pageTitle = "Business Process Improvement (BPI)";
const pageSlug = "business-process-improvement";


class BusinesProcessImprovement extends React.Component{
    render(){
      return(
        <Layout>

<section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">A system of performance that is deeply embedded in your company.
</h6>
        </div>
</section>

<section id="p_business_consulting" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                 
                  <h4>Why BPI will help you </h4>
                  <p>Successfully implemented BPI will contribute in your company to substantial improvements in performance, service enhancement, cost reductions, and productivity gains. Most take a BPI system in today's market to improve their organizational efficiency and achieve industry competitive advantage.</p>
                  <span className="break"></span>
                  <h5>BPI summary </h5>
                  <p>You can generate better quality with BPI while building a sustainable enhancement system.</p>
                  <h5>Lean Summary </h5>
                  <p>Lean aims to create more value while keeping the workers at its heart. An effective methodology for efficient business management. It is much more than just a set of tools, strategies, and projects. Lean is a full ideology to which you are committed, which contributes to faster results.</p>
                  <img src={lean_process} />
                  <h5>What's Six Sigma?</h5>
                  <p>Six Sigma is committed to continuously improving the value of your organization's products and services. This approach focuses on detecting and removing defects while reducing elements of chance that create sustainable and assured change in your processes. Six Sigma identifies, tests, analyzes, develops and monitors all procedures. It achieves the following goals:</p>
                  <ul>
                    <li>More satisfied customers</li>
                    <li>Reduced production time</li>
                    <li>Increase in profits</li>
                    <li>Reduced carbon footprint</li>
                  </ul>
                  <span className="break"></span>
                  <span className="break"></span>
                  <h4>BPI disciplines</h4>
                  <p>Each BPI discipline can act alone, but when combined together they are best. Our experts will evaluate your company and recommend the most effective approach for you.</p>
                  <span className="break"></span>
                  <h4>Six Sigma </h4>
                  <h5>Description</h5>
                  <p>Make improvements by eliminating differences and reducing process defects.</p>
                  <h5>Theory </h5>
                  <p>To achieve maximum performance, all processes must be described, evaluated, calculated, enhanced, and managed.</p>
                  <span className="break"></span>
                  <h4>Lean </h4>
                  <h5>Description</h5>
                  <p>A customer-centered approach that reduces all types of waste.</p>
                  <h5>Philosophy</h5>
                  <p>Maximize the interest of the consumer when eliminating things that do not add value.</p>
                  <span className="break"></span>
                  <h4>Rapid Improvement </h4>
                  <h5>Description</h5>
                  <p>A simple, creative system that solves known issues and is action-oriented.</p>
                  <h5>Philosophy</h5>
                  <p>Simple to execute quick fixes.</p>
                  <p>Rapid improvement is used when the problem is easily identifiable and solutions are expected to be implemented. Complex solutions are not needed for all challenges.</p>
                  <span className="break"></span>
                  <span className="break"></span>
                  <span className="break"></span>
                  <hr/>
                  <p>Align your processes with your plan! Let us assist you.
                  </p>
                  <p><a href="">Contact us.</a></p>

                
                  

                </div>
            </div>
        </div>
      </section>



</Layout>
    )
  }
}

export default BusinesProcessImprovement
